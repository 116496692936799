* {
  box-sizing: border-box;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

.header {
  background-color: #f1f1f1;
  text-align: center;
  font-size: 35px;
  font-family: 'Times New Roman', serif;
  color: #0060c2
}

.header.image {
    float: right;
    padding: 10px;
}

.row.content {
 background: repeating-linear-gradient(
        45deg,
        white,
        white 6px,
        white 2px,
        white 6px,
        #ccc 8px
      );
}

.column {
  float: left;
  width: 20%;
  padding: 10px;
}

.column.x {
    background-color:#999;
    height: 720px;
    color: white;
}

.column.y {
    background-color:#ddd;
    height: 720px;
    color: black;
}

.column.left {
  float: left;
  width: 40%;
  padding: 10px;
}

.column.right {
  float: left;
  width: 60%;
  padding: 10px;
}

.column.right.h2 {
   margin: auto;
   text-align: left;
   padding: 10px;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}


.footer {
  background-color: #be7b00;
  padding: 10px;
  text-align: center;
  color: white
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.p {
  font-family: 'verdana', Sans-serif;
      font-size: 15px;
      line-height: 2;
}

.b {
  font-family: 'verdana', Sans-serif;
      font-size: 20px;
}

@media (max-width: 1200px) {
  .column {
    width: 100%;
  }
}